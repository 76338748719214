import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import img404 from "../assets/images/404.png"
const NotFoundPage = () => {
  return (
    <Layout pageTitle="404 - Page Not Found">
      <Navbar />
      <PageBanner
        pageTitle="404 - Page Not Found"
        homePageText="Home"
        homePageUrl="/"
        activePageText="404"
      />

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={img404} alt="Error 404" style={{ maxWidth: 400 }} />
        <p>Sorry, the requested page could not be found.</p>
        <ReactWOW delay='.1s' animation='fadeInRight'>
          <div className="btn-box">
            <Link to="/" className="default-btn green">
              <i className="flaticon-right"></i>
              Back to Home <span></span>
            </Link>
          </div>
        </ReactWOW>
      </div>

      <Footer />
    </Layout>
  );
}

export default NotFoundPage;

